// @import compass

input.placeholder {
  color: $grey-darker;
}

.camera-btn {
  position: relative;
  overflow: hidden;

  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 300px;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.image-container {
  position: relative;
  min-height: 50px;
  min-width: 50px;
  display: inline-block;
  padding: 2px;
  border: 1px solid $grey;

  .btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .btn-dl {
    right: 55px;
  }
}

.image-preview {
  width: 100%;
  text-align: center;
  float: left;
  position: relative;

  img {
    max-width: 400px;
  }
}

.image-upload-field {
  form {
    margin: 0;
  }

  .progress {
    display: none;
  }

  .image-preview {
    img {
      margin: 0;
    }

    i.fa-sync {
      position: absolute;
      font-size: 26px;
      left: 50%;
      top: 50%;
      margin: (-13px) 0 0 -13px;
    }
  }
}

form {
  .field {
    margin-bottom: 10px;
    position: relative;

    input {
      &[type="text"],
      &[type="password"],
      &[type="email"] {
        width: auto;
        margin-bottom: 5px;
      }
    }
  }

  i {
    &.description {
      color: $grey-darker;
      font-size: 90%;
      clear: both;
      display: block;
    }
  }
}

.icon-field {
  display: block;
  border: 1px solid $grey;
  height: 40px;
  position: relative;

  &.first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
  }

  &.last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    height: 40px;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: absolute;
    padding-left: 30px;
    left: 0;
    right: 0;
    display: block;
    -webkit-font-smoothing: antialiased;

    &::-webkit-input-placeholder {
      color: $grey;
    }
  }

  i {
    width: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    float: left;
    font-size: 18px;
    color: $grey;
    vertical-align: middle;
    position: absolute;
    z-index: 50;
  }
}
