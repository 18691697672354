// Colors
$background-color: #F9F9F9;

$white: #FFFFFF;
$white-darker: #EEEEEE;

$black: #000000;

$green: #5bb75b;
$green-darker: #317256;

$red-lighter: #FFE6E0;
$red: #e2001a;
$red-darker: #950011;

$grey-lighter: #DDDDDD;
$grey: #CCCCCC;
$grey-darker: #767676;

$light-color: #EFEFEF;

$blue: #107dac;
$blue-darker: #005073;

$black-lighter: #363636;

// Breakpoints
$break-small: 480px;
$break-medium: 768px;
$break-large: 979px;
$break-extra-large: 1200px;
