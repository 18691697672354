// @import compass

h2.no-results {
  text-align: center;
  clear: both;
}

.empty_search_result {
  border: 1px solid $grey;
  background-color: white;
}

.assignment_result {
  margin-bottom: 40px;
  background-color: $white-darker;
  padding: 15px;
  float: left;
  width: 100%;

  // +box-sizing(border-box)
}
