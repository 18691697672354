ul.assignment-data {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    width: 14.28%;
    min-height: 100px;
    float: left;
    text-align: center;
    padding: 5px 0;
    border: 1px solid $grey-lighter;
    border-right: none;
    box-sizing: border-box;
    background: $white;

    label {
      margin-bottom: 0;
    }

    &:last-child {
      border-right: 1px solid $grey-lighter;
    }

    label {
      font-weight: 500;
      -webkit-font-smoothing: antiliased;
    }
  }
}


@media screen and (max-width: $break-large) and (min-width: $break-medium) {
  ul.assignment-data {
    font-size: 12px;
  }
}

@media screen and (max-width: $break-medium) {
  ul.assignment-data {
    border: 1px solid $grey-lighter;
    width: 100%;
    float: left;
    box-sizing: border-box;

    li {
      padding: 8px;
      width: 100%;
      text-align: left;
      border: none;
      border-bottom: 1px solid $grey-lighter;

      &:last-child {
        border: none;
      }

      label {
        display: none;
      }
    }
  }
}
