#sessions-new {
  padding-top: 20px;

  .navbar {
    display: none;
  }

  .container {
    min-width: 280px;
    max-width: 500px;
    padding: 0;
  }
}

#login {
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  h1 {
    margin: 0 auto;
    margin-bottom: 25px;
    img {
      min-width: 280px;
      max-width: 500px;
    }
  }

  .icon-field {
    box-sizing: border-box;
    overflow: hidden;
  }

  #user_email,
  #user_password {
    width: 100%;
  }

  .remember-me-field {
    line-height: 20px;
  }
}

#passwords-new, #confirmations-new {
  input[type=email] {
    width: 243px;
  }
}
