// @import compass

body {
  background: $background-color;
  padding-bottom: 30px;
}

#logo {
  margin-top: -8px;
}

dl {
  label {
    display: inline;
    margin: 0;
  }
}


.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 2px 5px;
  background: rgba($black, 0.8);
  text-align: center;
  color: $white;
  font-size: 12px;
  cursor: pointer;
}

.text-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.connection-error {
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
}

.navbar {
  .btn-navbar {
    background: $white-darker;
    color: $grey-darker;
    font-size: 16px;
    border: 1px solid $grey-lighter;
    text-shadow: none;
    margin: 7px;
    float: right;

    // +box-shadow(inset 0px 1px 1px rgba(black, .1))

    &.collapsed {
      background: $white;

      // +box-shadow(none)
      &:hover {
        background: $white;
      }
    }

    &:hover {
      background: $white-darker;
      color: $grey-darker;
    }
  }
}

@media screen and (max-width: $break-large) {
  .navbar {
    .nav-collapse {
      width: 100%;

      .nav {
        // +box-shadow(inset 0 1px 0 rgba(white, .1))
        border-top: 1px solid $grey-lighter;
        padding: 5px;
        float: left;
        width: 100%;
        margin: 0;

        li {
          float: left;
        }
      }
    }
  }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    .navbar-inner {
      padding: 0;
    }
  }
}

@media screen and (max-width: $break-medium) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    .navbar-inner {
      padding: 0;
    }
  }

  .navbar {
    &.navbar-fixed-top {
      margin: 0;

      padding {}
    }

    .navbar-brand {
      width: 33.3333%;
      margin-left: 33.3333%;
    }
  }
}

@media screen and (max-width: $break-medium) {
  ul.minimize-to-icon {
    li {
      label {
        display: none;
      }
    }
  }

  h2 {
    font-size: 18px;
    line-height: 1.5;
  }

  body {
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar {
    text-align: center;

    .navbar-brand {
      font-size: 12px;
    }
  }
}
