.text-center {
  text-align: center;
}

.text-green {
  color: $green;
  &.link {
    &:hover {
      color: $green-darker;
    }
  }
}
.text-red {
  color: $red;
  &.link {
    &:hover {
      color: $red-darker;
    }
  }
}
.text-grey {
  color: $grey;
}

.flex-align-center {
  display: flex;
  align-content: center;
}

.navbar-inner {
  background-image: linear-gradient(to bottom, $white, $white);
  box-shadow: 0 1px 10px rgba(0,0,0,0.1);
}

.container {
  padding: 5px 0;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.flex-even {
  flex: 1 1 0;
}

.label {
  font-size: 12px;
}

.big-icon {
  font-size: 30px;
}

.text-larger {
  font-size: 18px;
}

.hidden {
  display: none;
  visibility: hidden;
}

.m-0 {
  margin: 0;
}
.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 1.5em;
}
.m-3 {
  margin: 2em;
}
.m-4 {
  margin: 2.5em;
}
.m-5 {
  margin: 3em;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 1.5em;
}
.mt-3 {
  margin-top: 2em;
}
.mt-4 {
  margin-top: 2.5em;
}
.mt-5 {
  margin-top: 3em;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 1.5em;
}
.mb-3 {
  margin-bottom: 2em;
}
.mb-4 {
  margin-bottom: 2.5em;
}
.mb-5 {
  margin-bottom: 3em;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 1.5em;
}
.ml-3 {
  margin-left: 2em;
}
.ml-4 {
  margin-left: 2.5em;
}
.ml-5 {
  margin-left: 3em;
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 1.5em;
}
.mr-3 {
  margin-right: 2em;
}
.mr-4 {
  margin-right: 2.5em;
}
.mr-5 {
  margin-right: 3em;
}
