// Set the correct sprite paths
$icon-sprite-path: asset-path("twitter/bootstrap/glyphicons-halflings");
$icon-white-sprite-path: asset-path("twitter/bootstrap/glyphicons-halflings-white");

$link-color: $black-lighter;
$navbar-background: $white;
$navbar-height: 50px;
$grid-gutter-width768: 10px;
$input-border: $black-lighter;
$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 1170px
);
$theme-colors: (
  "primary": $grey-darker,
  "secondary": $red,
  "success": $green,
  "danger": $red,
  "info": $blue,
  "light": $light-color,
  "dark": $grey-lighter,
  "white": $white
);

@import "bootstrap";

body {
  font-size: 14px;
}

// .field_with_errors {
//   .control-group.error();
// }

// #error_explanation {
//   .alert();
//   .alert-error();
//   .alert-block();
// }

input.input-large-height {
  padding: 10px;
  font-size: $font-size-lg;
}
