// @import compass

.data-table {
  width: 100%;

  tr {
    td {
      width: 20%;
      border: 1px solid $grey-lighter;
      text-align: center;

      label {
        display: inline;
      }
    }
  }
}

@media screen and (max-width: $break-medium) {
  .data-table {
    tr {
      td {
        padding: 5px 0;

        i {
          clear: both;
          float: none;
          display: block;
        }

        label {
          display: none;
        }
      }
    }
  }
}

table {
  font-size: 14px;

  tr[data-href]:hover {
    background: $white-darker;
  }

  &.table {
    // +box-shadow(0 1px 2px rgba(black, .05))

    td {
      vertical-align: middle;
      padding: 15px 10px;
    }
  }
}

@media screen and (max-width: $break-medium) {
  .table th.minimize-icon {
    text-align: center;

    span {
      display: none;
    }
  }
}

@media screen and (min-width: $break-medium) {
  .table th.minimize-icon i {
    display: none;
  }
}

.status_not_finished {
  opacity: 0.4;
}
