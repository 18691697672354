// @import compass

.flash_message {
  border: 2px solid green;
  text-align: center;
  color: green;
  padding: 10px;
}

.note-image-preview-container {
  display: none;
}

@media screen and (max-width: $break-medium) {
  .note-image-preview-container {
    margin-bottom: 15px;
  }
}

.other_images {
  .image {
    width: 100%;
    float: left;
  }

  .progress {
    margin-bottom: 10px;
  }
}

.notes {
  .note {
    padding: 10px;
    border-bottom: 1px solid $white-darker;

    &:last-child {
      border-bottom: none;
    }

    time {
      font-size: 12px;
      color: $grey;
    }

    strong.name {
      color: $black-lighter;
    }

    p {
      margin: 5px 0;
    }
  }
}

.meta-data {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 0;

  ul.inline {
    text-align: left;
    margin: 0;

    li {
      margin: 0;
      padding: 0;

      .label {
        padding: 3px;
        background: rgba(black, 0.5);
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}


#assignments-show {
  .new_image {
    .progress {
      display: none;
    }
  }
}

#assignments-index .table {
  th, td {
    text-align: center;
  }
}

.camera-button-wrapper {
  text-align: center;
  position: relative;
  float: left;
  width: 100%;
  margin-top: 15px;

  // +box-sizing(border-box)

  input[type="file"] {
    position: absolute;
    top: -20px;
    left: 0px;
    visibility: hidden;
  }

  .icon-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: (-12px) 0 0 -12px;
    font-size: 25px;
  }

  label {
    width: 100%;
    float: left;
    cursor: pointer;
    padding: 10px;
    margin: 0;
    border: 1px solid $grey-lighter;
    text-align: center;
    margin-top: 5px;
    box-sizing: border-box;

    i {
      margin-right: 5px;
    }
  }
}
