@media screen and (max-width: $break-medium) {
  table {
    td.phone-number,
    th.phone-number {
      display: none;
    }
  }
}

tr.user_deactivated {
  background-color: $red-lighter;
}

.user_deactivated {
  background-color: $red-lighter;
  padding: 10px;
}

a.dropdown-item:hover {
  background: $grey-lighter!important;
}
